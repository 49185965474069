.welcome {
    min-height: 100vh;
}

/*.login-header {*/
/*    background-color: #282c34;*/
/*    min-height: 100vh;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: calc(10px + 2vmin);*/
/*    color: white;*/
/*}*/

/*.login-link {*/
/*    color: #61dafb;*/
/*}*/

.v-line {
    background-image: linear-gradient(#FFF, #FFF);
    background-size: 2px 100%;
    background-repeat: no-repeat;
    background-position: center center;
}